import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Link from "gatsby-plugin-transition-link";
// import { StaticImage } from "gatsby-plugin-image";

import { mapEdgesToNodes } from "../../lib/helpers";
import ModalContact from "../Modal/ModalContact";
import logo from "../../images/Global/Footer/Deblanc logo.svg";
import nestLogo from "../../images/Global/Footer/Nest Logo.svg";
import sbaLogo from "../../images/Global/Footer/SDVOC- badge.png";

const Footer = ({ hideFooter }) => {
  const getYear = () => {
    return new Date().getFullYear();
  };

  const [cityDropdownOpen, setCityDropdownOpen] = useState(false);

  const citiesClickHandler = () => {
    setCityDropdownOpen(!cityDropdownOpen);
  };

  const data = useStaticQuery(graphql`
    {
      cities: allSanityCities(sort: { fields: [title], order: ASC }) {
        edges {
          node {
            title
            slug {
              current
            }
          }
        }
      }
    }
  `);

  const cityNodes = data && data.cities && mapEdgesToNodes(data.cities);

  const navigation = {
    explore: [
      {
        name: "Business Services",
        href: "/business-cpa-services/",
      },
      {
        name: "Personal Services",
        href: "/cpa-services-for-individuals/",
      },
      {
        name: "Pricing",
        href: "/bookkeeping-accounting-services/",
      },
      {
        name: "Blog",
        href: "/tax-accounting-blog/",
      },
    ],
    company: [
      {
        name: "About Us",
        href: "/about/",
      },
      {
        name: "Meet the Team",
        href: "/meet-the-team/",
      },
      {
        name: "Reviews",
        href: "/reviews/",
      },
      {
        name: "FAQs",
        href: "/faqs/",
      },
    ],
  };

  return (
    <>
      <footer
        className={`relative z-20 border-t border-primary-500 bg-[#020301] pt-20 pb-12 text-center md:pt-18 md:pb-5 md:text-left ${
          hideFooter && "hidden"
        }`}
      >
        <div className="container">
          <div className="mb-14 grid space-y-14 md:mb-24 md:grid-cols-12 md:justify-between md:space-y-0 md:space-x-16 xl:space-x-24">
            <div className="md:col-span-4 md:col-start-1">
            <div>
              <Link to="/">
                <img
                  src={logo}
                  width={219}
                  className="mx-auto inline-block w-[190px] md:mx-0 md:w-[219px]"
                />
              </Link>
              </div>
              <div>
              <img
                  src={sbaLogo}
                  width={101}
                  className="mx-auto inline-block w-[101px] md:mx-0 md:w-[101px] mt-6 md:mt-[5.5rem]"
                />
              </div>

              <div className="mt-14 flex items-center justify-center space-x-6 md:hidden">
                <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                  className="text-white no-underline hover:text-primary-500"
                >
                  <i className="fab fa-facebook-f text-xl"></i>
                </a>
                <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                  className="text-white no-underline hover:text-primary-500"
                >
                  <i className="fa-brands fa-google text-xl"></i>
                </a>
                <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                  className="text-white no-underline hover:text-primary-500"
                >
                  <i className="fab fa-twitter text-xl"></i>
                </a>
                <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                  className="text-white no-underline hover:text-primary-500"
                >
                  <i className="fab fa-linkedin-in text-xl"></i>
                </a>
              </div>
            </div>

            <div className="md:col-span-8 md:col-end-13">
              <div className="grid justify-center gap-y-14 md:flex md:grid-cols-2 md:justify-between md:gap-y-0 md:space-x-16 lg:space-x-32">
                <div>
                  <div className="mb-4 font-semibold text-gray-50">Explore</div>
                  <ul className="flex flex-col space-y-1.5">
                    {navigation.explore.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className="hover:text-primary-700 text-sm font-normal text-white/70 no-underline hover:text-white"
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>

                <div>
                  <div className="mb-4 font-semibold text-gray-50">Company</div>
                  <ul className="flex flex-col space-y-1.5">
                    {navigation.company.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className="hover:text-primary-700 text-sm font-normal text-white/70 no-underline hover:text-white"
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>

                <div>
                  <div className="mb-4 font-semibold text-gray-50">Contact</div>
                  <ul className="flex flex-col space-y-4">
                    <li>
                      <a
                        href="https://goo.gl/maps/JxDDacpHq5wKtUz17"
                        target="_blank"
                        rel="noreferrer"
                        className="hover:text-primary-700 text-sm font-normal text-white/70 no-underline hover:text-white"
                      >
                        3261 Old Washington Road, <br/>
                        Suite 2033B,<br />
                        Waldorf, Maryland 20602
                      </a>
                    </li>
                    <li>
                      <a
                        href="tel:301-609-7515"
                        target="_blank"
                        rel="noreferrer"
                        className="hover:text-primary-700 text-sm font-normal text-white/70 no-underline hover:text-white"
                      >
                        (301) 609-7515
                      </a>
                    </li>
                    <li>
                      <a
                        href="mailto:info@deblancmurphy.com"
                        target="_blank"
                        rel="noreferrer"
                        className="hover:text-primary-700 text-sm font-normal text-white/70 no-underline hover:text-white"
                      >
                        info@deblancmurphy.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col flex-wrap justify-items-center md:flex-row md:flex-nowrap md:items-center md:justify-between">
            <div className="md:flex md:items-center">
              <ul className="flex flex-wrap items-center justify-center md:justify-start md:space-x-4">
                <div className="flex w-full flex-wrap justify-center space-y-3 md:w-auto md:space-y-0 md:space-x-5">
                  <li className="w-full text-sm text-white/70 md:w-auto">
                    © {getYear()} DeBlanc, Murphy & Murphy
                  </li>

                  <li className="w-full text-sm md:w-auto">
                    <Link
                      to="/privacy-policy/"
                      className="hover:text-primary-700 font-body font-normal text-white/70 no-underline hover:text-white"
                    >
                      Privacy Policy
                    </Link>
                  </li>

                  <li className="text-sm">
                    <button
                      className="relative font-normal text-white/70 no-underline hover:text-white"
                      onClick={citiesClickHandler}
                    >
                      Cities
                      <i
                        className={`far fa-chevron-down ml-1 transform text-sm transition-all duration-300 ease-linear ${
                          cityDropdownOpen ? "rotate-180" : "rotate-0"
                        }`}
                      ></i>
                      <ul
                        className={`absolute bottom-0 left-0 flex max-h-28 transform flex-col space-y-2.5 overflow-y-scroll rounded-xl bg-gray-900 px-3 pt-4 pb-3 text-left transition-all duration-300 ease-linear md:px-4 md:pb-4 md:pt-5 ${
                          cityDropdownOpen
                            ? "visible -translate-y-8 opacity-100"
                            : "invisible -translate-y-4 opacity-0"
                        }`}
                      >
                        {cityNodes.map((item, i) => (
                          <li
                            className="whitespace-nowrap border-b border-primary-500 pb-1.5 text-xs"
                            key={i}
                          >
                            {item.slug ? (
                              <Link
                                to={`/${item.slug.current}/`}
                                className="font-semibold text-typography-body no-underline hover:text-primary-500"
                              >
                                {item.title}
                              </Link>
                            ) : (
                              item.title
                            )}
                          </li>
                        ))}
                      </ul>
                    </button>
                  </li>

                  <div className="mt-10 w-full text-sm md:mt-0 md:w-auto">
                    <a
                      className="hover:text-primary-700 group flex items-center justify-center space-x-2 text-sm font-normal text-white/70 no-underline hover:text-white md:justify-start"
                      href="https://www.wisedigitalpartners.com/nest-by-wise/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>Powered by</span>
                      <img
                        src={nestLogo}
                        alt="NEST logo"
                        className="opacity-70 transition-opacity duration-300 ease-linear group-hover:opacity-100"
                      />
                    </a>
                  </div>
                </div>
              </ul>
            </div>

          
          </div>
        </div>
      </footer>

      <ModalContact />
    </>
  );
};

export default Footer;

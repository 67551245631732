import React from "react";
import MicroModal from "micromodal";
import styled from "@emotion/styled";
import tw from "twin.macro";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Contact from "../Form/Contact";

const StyledModal = styled.div`
  .modal {
    &[aria-hidden="false"] {
      .overlay {
        animation: mmfadeIn 0.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
    &[aria-hidden="false"] {
      .content-wrapper {
        animation: mmslideIn 0.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
    &[aria-hidden="true"] {
      .overlay {
        animation: mmfadeOut 0.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
    &[aria-hidden="true"] {
      .content-wrapper {
        animation: mmslideOut 0.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
  }
`;
const StyledTabs = styled.div`
  .react-tabs__tab-list {
    ${tw`grid grid-cols-2 mb-6 md:mb-10 bg-[#373835] font-bold rounded-5xl overflow-hidden border border-green-300`}
  }
  .react-tabs__tab {
    ${tw`bg-[#373835] text-[#F9FCF4] py-3 px-4 flex rounded-none items-center justify-center w-full border-none bottom-0`}
  }
  .react-tabs__tab--selected {
    ${tw`text-white text-opacity-100 rounded-5xl bg-gradient-to-r from-secondary-500 to-primary-500`}
    svg path {
      ${tw`text-white`}
    }
  }
`;

const ContactModal = () => {
  if (typeof window !== "undefined") {
    MicroModal.init({
      openTrigger: "data-modal-open",
      closeTrigger: "data-modal-close",
      disableFocus: true,
      disableScroll: true,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
    });
  }

  return (
    <StyledModal>
      <div
        className="modal relative z-50 hidden"
        id="modal-contact"
        aria-hidden="false"
      >
        <div
          className="overlay fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center bg-black/40 outline-none"
          tabIndex="-1"
          data-modal-close
        >
          <div
            className="content-wrapper ml-auto h-screen w-full max-w-[720px] overflow-auto bg-black"
            role="dialog"
            aria-modal="true"
          >
            <div className="flex items-center justify-between border-b border-gray-500/20 px-5 pt-14 pb-4 md:py-4">
              <a
                href="tel:301-609-7515"
                className="flex items-center space-x-2 text-sm font-bold text-white no-underline hover:text-primary-500"
              >
                (301) 609-7515
              </a>
              <i
                className="close far fa-times cursor-pointer text-xl text-white transition-all duration-300 ease-linear hover:text-primary-500"
                data-modal-close
              ></i>
            </div>
            <div className="px-4 pt-8 pb-24 md:px-18">
            <StyledTabs>
                <Tabs
                // selectedIndex={0}
                // onSelect={(index) => setTabIndex(index)}
                >
                  <TabList>
                    <Tab>Book a meeting</Tab>
                    <Tab>Send a message</Tab>
                  </TabList>

                  <TabPanel>
                  <header className="mb-8">
                
                <p className="text-center">
                Want to talk taxes? Ask about accounting? Book a meeting to connect with one of our CPA professionals!
                </p>
              </header>
                    <iframe
                      src=" https://calendly.com/chrisdeblanc/meeting-with-chris"
                      title="Book an appointment with Deblanc + Murphy"
                      scrolling="yes"
                      className="h-[800px] w-full"
                    ></iframe>
                  </TabPanel>

                  <TabPanel>
                 
                  
            
              <header className="mb-8">
                <p className="heading-three text-center">Have a Question?</p>
                <p className="text-center">
                  Let us know how we can help, and we’ll connect you with one of
                  our professionals. Contact us today!
                </p>
              </header>

              <Contact />
            
            </TabPanel>
                </Tabs>
              </StyledTabs>
              </div>
          </div>
        </div>
      </div>
    </StyledModal>
  );
};
export default ContactModal;

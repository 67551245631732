import React, { useState, useRef } from "react";
import { useOnClickOutside } from "../../hooks";
import Link from "gatsby-plugin-transition-link";

import Burger from "./Burger";
import OffCanvas from "./OffCanvas";
import ButtonSolid from "../Button/ButtonSolid";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemState,
  AccordionItemPanel,
} from "react-accessible-accordion";

import Logo from "../../images/Global/Nav/Logo.svg";
import phone from "../../images/Global/Nav/Utility Phone number.svg";
import payment from "../../images/Global/Nav/Utility Pay Bill.svg";
import signIn from "../../images/Global/Nav/Utility Client Login.svg";

const MainNav = ({ navigationStyle, scrolled, offcanvasOffset }) => {
  // determine if offcanvas is open
  const [offcanvasOpen, setOffcanvasOpen] = useState(false);

  const [subMenuHovering1, setSubMenuHovering1] = useState(false);
  const isHoveringSubMenu1 = () => setSubMenuHovering1(true);
  const notHoveringSubMenu1 = () => setSubMenuHovering1(false);

  const [subMenuHovering2, setSubMenuHovering2] = useState(false);
  const isHoveringSubMenu2 = () => setSubMenuHovering2(true);
  const notHoveringSubMenu2 = () => setSubMenuHovering2(false);

  const [subMenuHovering3, setSubMenuHovering3] = useState(false);
  const isHoveringSubMenu3 = () => setSubMenuHovering3(true);
  const notHoveringSubMenu3 = () => setSubMenuHovering3(false);

  const [subMenuHovering4, setSubMenuHovering4] = useState(false);
  const isHoveringSubMenu4 = () => setSubMenuHovering4(true);
  const notHoveringSubMenu4 = () => setSubMenuHovering4(false);

  // handle click of  navigation items
  const clickHandler = () => {
    setOffcanvasOpen(!offcanvasOpen);
  };

  // close offcanvas onclick outside
  const node = useRef();
  useOnClickOutside(node, () => setOffcanvasOpen(false));

  const navigation = {
    business: [
      {
        name: "All Business Services",
        href: "/business-cpa-services/",
      },
      {
        name: "Business Tax Preparation",
        href: "/business-tax-preparation-services/",
      },
      {
        name: "Business Tax Planning",
        href: "/business-tax-planning-services/",
      },
      {
        name: "Accounting & Bookkeeping",
        href: "/outsourced-accounting-bookkeeping-services/",
      },
      {
        name: "Business Consulting",
        href: "/business-consulting-services/",
      },
      {
        name: "Business Valuation",
        href: "/business-valuation-services/",
      },
      {
        name: "Audit and Attestation Services",
        href: "/tax-audit-attestation-services/",
      },
      {
        name: "IRS Representation",
        href: "/irs-representation-services/",
      },
    ],
    personal: [
      {
        name: "All Personal Services",
        href: "/cpa-services-for-individuals/",
      },
      {
        name: "Personal Tax Prep & Planning",
        href: "/personal-tax-preparation-planning-services/",
      },
      {
        name: "Estate & Trust Planning",
        href: "/estate-trust-planning-services/",
      },
      {
        name: "Crypto Consulting",
        href: "/crypto-consulting-services/",
      },
      {
        name: "Family Business",
        href: "/family-owned-business-cpa/",
      },
      {
        name: "IRS Representation",
        href: "/irs-representation-services/",
      },
      {
        name: "Wealth Management",
        href: "/wealth-management-services/",
      },
    ],
    about: [
      {
        name: "About Us",
        href: "/about/",
      },
      {
        name: "Meet The Team",
        href: "/meet-the-team/",
      },
      {
        name: "FAQs",
        href: "/faqs/",
      },
    ],
    pricing: [
      {
        name: "Bookkeeping",
        href: "/bookkeeping-accounting-services/",
      },
      {
        name: "Wealth Management",
        href: "/financial-plans/",
      },
      {
        name: "Personal Tax Prep",
        href: "/personal-tax-prep/",
      },
    ],
  };

  return (
    <nav
      id="main-navigation"
      className={`flex h-16 w-full items-center bg-[#0B0C0B] transition duration-300 ease-linear after:absolute after:left-0 after:bottom-0 after:h-px after:w-0 after:bg-primary-500/50 lg:h-20 ${
        navigationStyle === "overlap"
          ? "absolute lg:bg-[#141513]/40"
          : "after:!w-full lg:bg-[#0B0C0B]"
      } ${offcanvasOpen && "!bg-[#0B0C0B]"} ${
        scrolled &&
        "!fixed top-0 left-0 z-50 w-full !bg-[#0B0C0B] after:!w-full"
      } `}
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="container flex items-center justify-between">
        <div className="flex flex-auto lg:hidden">
          <a
            href="tel:301-609-7515"
            className="flex items-center space-x-1.5 text-sm font-bold text-white no-underline hover:text-white"
          >
            <img src={phone} width={20} alt="Phone" />
          </a>
        </div>
        <div className="flex flex-auto items-center justify-center lg:justify-start">
          <Link to="/">
            <img
              src={Logo}
              alt="DeBlanc + Murphy Logo"
              width={225}
              className="w-[186px] lg:w-[225px]"
            />
          </Link>

          <ul
            id="navigation-desktop"
            className="hidden lg:ml-8 lg:flex lg:flex-row lg:items-center lg:justify-end lg:space-x-5 xl:space-x-7"
          >
            <li
              className="relative"
              role="presentation"
              onMouseEnter={isHoveringSubMenu1}
              onMouseLeave={notHoveringSubMenu1}
            >
              <Link
                to="/business-cpa-services/"
                className={`relative pb-8 font-body text-sm font-semibold no-underline hover:text-primary-500 ${
                  subMenuHovering1 ? "text-primary-500" : "text-white"
                }`}
              >
                Business
                <i className="fal fa-chevron-down ml-1.5"></i>
              </Link>
              <ul
                className={`shadow-4xl absolute top-0 z-10 flex min-w-[305px] -translate-x-4 transform flex-col space-y-2 rounded-3xl bg-black px-10 pb-10 pt-8 transition-all duration-300 ease-linear ${
                  subMenuHovering1
                    ? " visible translate-y-[52px] opacity-100"
                    : "invisible translate-y-20 opacity-0"
                }`}
              >
                {navigation.business.slice(1).map((item) => (
                  <li
                    key={item.name}
                    className="border-b border-primary-500/50 pb-1.5"
                  >
                    <Link
                      to={item.href}
                      className="font-semibold text-typography-body no-underline hover:text-primary-500"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>

            <li
              className="relative"
              role="presentation"
              onMouseEnter={isHoveringSubMenu2}
              onMouseLeave={notHoveringSubMenu2}
            >
              <Link
                to="/cpa-services-for-individuals/"
                className={`relative pb-8 font-body text-sm font-semibold no-underline hover:text-primary-500 ${
                  subMenuHovering2 ? "text-primary-500" : "text-white"
                }`}
              >
                Individuals <i className="fal fa-chevron-down ml-1.5"></i>
              </Link>
              <ul
                className={`shadow-4xl absolute top-0 z-10 flex min-w-[305px] -translate-x-4 transform flex-col space-y-2 rounded-3xl bg-black px-10 pb-10 pt-8 transition-all duration-300 ease-linear ${
                  subMenuHovering2
                    ? " visible translate-y-[52px] opacity-100"
                    : "invisible translate-y-20 opacity-0"
                }`}
              >
                {navigation.personal.slice(1).map((item) => (
                  <li
                    key={item.name}
                    className="border-b border-primary-500/50 pb-1.5"
                  >
                    <Link
                      to={item.href}
                      className="font-semibold text-typography-body no-underline hover:text-primary-500"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>

            <li
              className="relative"
              role="presentation"
              onMouseEnter={isHoveringSubMenu3}
              onMouseLeave={notHoveringSubMenu3}
            >
              <Link
                to="/about/"
                className={`relative pb-8 font-body text-sm font-semibold no-underline hover:text-primary-500 ${
                  subMenuHovering3 ? "text-primary-500" : "text-white"
                }`}
              >
                About <i className="fal fa-chevron-down ml-1.5"></i>
              </Link>
              <ul
                className={`shadow-4xl absolute top-0 z-10 flex min-w-[305px] -translate-x-4 transform flex-col space-y-2 rounded-3xl bg-black px-10 pb-10 pt-8 transition-all duration-300 ease-linear ${
                  subMenuHovering3
                    ? " visible translate-y-[52px] opacity-100"
                    : "invisible translate-y-20 opacity-0"
                }`}
              >
                {navigation.about.map((item) => (
                  <li
                    key={item.name}
                    className="border-b border-primary-500/50 pb-1.5"
                  >
                    <Link
                      to={item.href}
                      className="font-semibold text-typography-body no-underline hover:text-primary-500"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>

            <li
              className="relative"
              role="presentation"
              onMouseEnter={isHoveringSubMenu4}
              onMouseLeave={notHoveringSubMenu4}
            >
              <Link
                to="#"
                className={`relative pb-8 font-body text-sm font-semibold no-underline hover:text-primary-500 ${
                  subMenuHovering4 ? "text-primary-500" : "text-white"
                }`}
              >
                Pricing <i className="fal fa-chevron-down ml-1.5"></i>
              </Link>
              <ul
                className={`shadow-4xl absolute top-0 z-10 flex min-w-[305px] -translate-x-4 transform flex-col space-y-2 rounded-3xl bg-black px-10 pb-10 pt-8 transition-all duration-300 ease-linear ${
                  subMenuHovering4
                    ? " visible translate-y-[52px] opacity-100"
                    : "invisible translate-y-20 opacity-0"
                }`}
              >
                {navigation.pricing.map((item) => (
                  <li
                    key={item.name}
                    className="border-b border-primary-500/50 pb-1.5"
                  >
                    <Link
                      to={item.href}
                      className="font-semibold text-typography-body no-underline hover:text-primary-500"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>

            <li className="group">
              <Link
                to="/reviews/"
                className={`relative pb-8 font-body text-sm font-semibold text-white no-underline after:absolute hover:text-primary-500`}
              >
                Reviews
              </Link>
            </li>
            <li className="group">
              <Link
                to="/tax-accounting-blog/"
                className={`relative pb-8 font-body text-sm font-semibold text-white no-underline after:absolute hover:text-primary-500`}
              >
                Resources
              </Link>
            </li>
          </ul>
        </div>

        <div className="flex flex-auto items-center justify-end">
          <div className="hidden items-center space-x-4 lg:inline-flex">
            <ButtonSolid modal="modal-contact" text="Talk To An Advisor" />
          </div>

          <div className="lg:hidden" ref={node}>
            {/* Burger */}
            <Burger
              offcanvasOpen={offcanvasOpen}
              setOffcanvasOpen={setOffcanvasOpen}
              navigationStyle={navigationStyle}
              scrolled={scrolled}
              aria-controls="offcanvas-navigation"
            />

            {/* Mobile Nav */}
            <OffCanvas
              offcanvasOpen={offcanvasOpen}
              offcanvasOffset={offcanvasOffset}
              id="offcanvas-navigation"
            >
              <div>
                <ul id="navigation-mobile" className="mb-24 px-4 text-center">
                  <Accordion
                    allowZeroExpanded={true}
                    className="flex flex-col space-y-6"
                  >
                    <li className="relative pb-6 after:absolute after:left-0 after:bottom-0 after:h-px after:w-full after:bg-gradient-to-r after:from-secondary-500 after:to-primary-500">
                      <AccordionItem uuid={1}>
                        <AccordionItemButton className="flex items-center justify-center focus:outline-none">
                          <AccordionItemState>
                            {(state) => {
                              return (
                                <>
                                  <div
                                    className={`mb-0 text-3xl font-bold no-underline transition-colors duration-300 ease-linear ${
                                      state.expanded
                                        ? "text-primary-500"
                                        : "text-white hover:text-white"
                                    }`}
                                  >
                                    Business
                                  </div>
                                </>
                              );
                            }}
                          </AccordionItemState>
                        </AccordionItemButton>

                        <AccordionItemPanel className="pt-5">
                          <ul className="space-y-3.5">
                            {navigation.business.map((item) => (
                              <li key={item.name} className="whitespace-nowrap">
                                <Link
                                  to={item.href}
                                  onClick={clickHandler}
                                  className="block font-bold text-typography-heading/50 no-underline hover:text-typography-heading"
                                >
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </li>

                    <li className="relative pb-6 after:absolute after:left-0 after:bottom-0 after:h-px after:w-full after:bg-gradient-to-r after:from-secondary-500 after:to-primary-500">
                      <AccordionItem uuid={2}>
                        <AccordionItemButton className="flex items-center justify-center focus:outline-none">
                          <AccordionItemState>
                            {(state) => {
                              return (
                                <>
                                  <div
                                    className={`mb-0 text-3xl font-bold no-underline transition-colors duration-300 ease-linear ${
                                      state.expanded
                                        ? "text-primary-500"
                                        : "text-white hover:text-white"
                                    }`}
                                  >
                                    Individuals
                                  </div>
                                </>
                              );
                            }}
                          </AccordionItemState>
                        </AccordionItemButton>

                        <AccordionItemPanel className="pt-5">
                          <ul className="space-y-3.5">
                            {navigation.personal.map((item) => (
                              <li key={item.name} className="whitespace-nowrap">
                                <Link
                                  to={item.href}
                                  onClick={clickHandler}
                                  className="block font-bold text-typography-heading/50 no-underline hover:text-typography-heading"
                                >
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </li>

                    <li className="relative pb-6 after:absolute after:left-0 after:bottom-0 after:h-px after:w-full after:bg-gradient-to-r after:from-secondary-500 after:to-primary-500">
                      <AccordionItem uuid={3}>
                        <AccordionItemButton className="flex items-center justify-center focus:outline-none">
                          <AccordionItemState>
                            {(state) => {
                              return (
                                <>
                                  <div
                                    className={`mb-0 text-3xl font-bold no-underline transition-colors duration-300 ease-linear ${
                                      state.expanded
                                        ? "text-primary-500"
                                        : "text-white hover:text-white"
                                    }`}
                                  >
                                    About
                                  </div>
                                </>
                              );
                            }}
                          </AccordionItemState>
                        </AccordionItemButton>

                        <AccordionItemPanel className="pt-5">
                          <ul className="space-y-3.5">
                            {navigation.about.map((item) => (
                              <li key={item.name} className="whitespace-nowrap">
                                <Link
                                  to={item.href}
                                  onClick={clickHandler}
                                  className="block font-bold text-typography-heading/50 no-underline hover:text-typography-heading"
                                >
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </li>

                    <li className="relative pb-6 after:absolute after:left-0 after:bottom-0 after:h-px after:w-full after:bg-gradient-to-r after:from-secondary-500 after:to-primary-500">
                      <AccordionItem uuid={4}>
                        <AccordionItemButton className="flex items-center justify-center focus:outline-none">
                          <AccordionItemState>
                            {(state) => {
                              return (
                                <>
                                  <div
                                    className={`mb-0 text-3xl font-bold no-underline transition-colors duration-300 ease-linear ${
                                      state.expanded
                                        ? "text-primary-500"
                                        : "text-white hover:text-white"
                                    }`}
                                  >
                                    Pricing
                                  </div>
                                </>
                              );
                            }}
                          </AccordionItemState>
                        </AccordionItemButton>

                        <AccordionItemPanel className="pt-5">
                          <ul className="space-y-3.5">
                            {navigation.pricing.map((item) => (
                              <li key={item.name} className="whitespace-nowrap">
                                <Link
                                  to={item.href}
                                  onClick={clickHandler}
                                  className="block font-bold text-typography-heading/50 no-underline hover:text-typography-heading"
                                >
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </li>

                    <li className="relative pb-6 after:absolute after:left-0 after:bottom-0 after:h-px after:w-full after:bg-gradient-to-r after:from-secondary-500 after:to-primary-500">
                      <Link
                        to="/reviews/"
                        onClick={clickHandler}
                        className="text-3xl font-bold text-white no-underline transition-colors duration-300 ease-linear hover:text-white"
                      >
                        Reviews
                      </Link>
                    </li>
                    <li className="relative pb-6 after:absolute after:left-0 after:bottom-0 after:h-px after:w-full after:bg-gradient-to-r after:from-secondary-500 after:to-primary-500">
                      <Link
                        to="/tax-accounting-blog/"
                        onClick={clickHandler}
                        className="text-3xl font-bold text-white no-underline transition-colors duration-300 ease-linear hover:text-white"
                      >
                        Resources
                      </Link>
                    </li>
                  </Accordion>
                </ul>

                <div className="mb-6 flex flex-col items-center space-y-4">
                  <div>
                    <a
                      href="/client-portal/"
                      target="_blank"
                      rel="noreferrer"
                      className="flex items-center space-x-1.5 text-sm font-bold text-white no-underline hover:text-white"
                    >
                      <img src={signIn} width={14} alt="Login" />
                      <span>Client Portal </span>
                    </a>
                  </div>
                  <div>
                    <Link
                      to="https://secure.cpacharge.com/pages/deblancmurphy/payments"
                      className="flex items-center space-x-1.5 text-sm font-bold text-white no-underline hover:text-white"
                    >
                      <img src={payment} width={24} alt="Pay Your Bill" />
                      <span>Pay Your Bill</span>
                    </Link>
                  </div>
                </div>

                <ButtonSolid
                  href="/get-a-quote/"
                  text="Get a Quote"
                  className="w-full"
                />
              </div>
            </OffCanvas>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default MainNav;

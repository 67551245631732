import React, { useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import Header from "./Navigation/Header";
import Footer from "./Footer/Footer";

const Layout = ({ children, navigationStyle, hideHeader, hideFooter }) => {
  const [bodyOffset, setBodyOffset] = useState(0);
  return (
    <div>
      <Helmet></Helmet>
      <Header
        navigationStyle={navigationStyle}
        hideHeader={hideHeader}
        setBodyOffset={setBodyOffset}
      />
      <main
        id="body-content"
        className="bg-[#1D1D1D]"
        style={{ paddingTop: bodyOffset + "px" }}
      >
        {children}
      </main>
      <Footer hideFooter={hideFooter} />
    </div>
  );
};

Layout.propTypes = {
  navigationStyle: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Layout;

import { isFuture } from "date-fns";

export function cn(...args) {
  return args.filter(Boolean).join(" ");
}

export function mapEdgesToNodes(data) {
  if (!data.edges) return [];
  return data.edges.map((edge) => edge.node);
}

export function filterOutDocsWithoutSlugs({ slug }) {
  return (slug || {}).current;
}

export function excludeFeaturedPosts({ featured }) {
  return !featured;
}

export function includeFeaturedPosts({ featured }) {
  return featured;
}

export function filterOutDocsPublishedInTheFuture({ publishedAt }) {
  return !isFuture(new Date(publishedAt));
}

export function filterOutPostsFromOtherCategories(blogpost, currentCategory) {
  if (currentCategory && blogpost.categories && currentCategory._id !== "all") {
    const categoryArray = blogpost.categories.map((cat) => cat._id);
    if (categoryArray.includes(currentCategory._id)) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

export function getBlogUrl(slug) {
  return `/${slug.current || slug}/`;
}

export function toPlainText(blocks) {
  if (!blocks) {
    return "";
  }
  return blocks
    .map((block) => {
      if (block._type !== "block" || !block.children) {
        return "";
      }
      return block.children.map((child) => child.text).join("");
    })
    .join("\n\n");
}
